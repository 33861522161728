.header-home {
  display: block;
  margin: 0 auto;
  text-align: center;
  position: relative;

  .link,
  .selfie {
    width: 100px;
    margin-bottom: 4px;
    border-radius: 100%;
    transition: all 0.2s;
    box-shadow: 0;
    opacity: 1;

    &:hover {
      box-shadow: 0 0 4px 0 rgba(0,0,0,0.18), 0 0 12px 0 rgba(0,0,0,0.15);
      opacity: 0.8;
    }
  }

  .title-main-header {
    margin: 0 0 13px;
    text-align: left;
  }

  .title {
    margin: 0 0 13px;
  }

  .description {
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    color: $black;
    margin: 0;
  }
  // if you put some links in your bio. ;)
  a {
    font-weight: 400;
  }

  .summary{
    text-align: left;
    color: $black;
    font-weight: normal;
  }
}

.header-split{
  display: flex;
  @include largeS{
    flex-wrap: wrap;
  }
  .header-split-left{
    min-width: 300px;
  }

  .header-split-right{
    padding: 32px;
    @include largeS{
      padding: 32px 0;
    }
  }
}

.header-text{
  margin-bottom: 80px;
  margin-top: 24px;
}

.nav-home {
  position: fixed;
  width: 100%;
  padding-top: 24px;
  text-align: center;
  z-index: 99;
  background-color: white;
  transition: all 0.4s ease-in-out;
  .list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .item {
    display: inline-block;
  }

  .item,
  .link {
    display: inline-block;
    font-weight: 300;
    text-decoration: none;
    font-size: 16px;
    padding: 7px 15px;
    margin: 0;
    border-radius: 4%;
    transition: all 0.4s ease-in-out;
    width: 70px;

    &:hover {
      color: $alpha;
    }
  }

  .link{padding: 0;}
}

.nav-small{
  text-align: right;
  transition: all 0.4s ease-in-out;
  padding-top: 0;
  .list{
    max-width: 1000px;
    margin: auto;
  }
  .item,.link {
    display: inline-block;
    font-weight: 300;
    text-decoration: none;
    font-size: 16px;
    //padding: 7px 15px;
    margin: 0;
    border-radius: 4%;
    transition: all 0.4s ease-in-out;
    width: 70px;

    &:hover {
      color: $alpha;
    }
  }
}

.footer{
  max-width: 1000px;
  margin: auto;
  text-align: left;

  @include largeS{
    max-width: 744px;
  }

  @include mediumS{
    max-width: 368px;
  }
  @include small{
    max-width: calc(100vw-16px);
  }

  .social{
    padding: 0 8px;
  }
}

body,
html {
  font-size: 62.5%;
}

body {
  line-height: 1;
  font: 16px $fontSans;
  color: $alpha;
}

h1,
h2,
h3,
h4 {
  font-family: $fontSans;
  color: $beta;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

h1 {
  font-size: 60px;
  letter-spacing: -0.5px;
  color: $beta;
  line-height: 72px;
  font-weight: 200;
}

h2 {
  font-size: 48px;
}

h3 {
  font-size: 24px;
}


a {
  color: $delta;
  text-decoration: none;
}

p {
  line-height: 1.5;
  color: $black;
  font-weight: 400;
  margin-bottom: 20px;
  letter-spacing: 0.4px;
  @media #{$mobile} {
    letter-spacing: 0.2px;
  }
}

strong {
  font-weight: 400;
  color: #000;
}

ol,
ul {
  li {
    line-height: 2.4rem;
    font-weight: 300;
    color: $alpha;
  }
}

iframe,
img,
pre {
  max-width: 100%;
}

img,
pre {
  border-radius: 4px;
}

figcaption {
  position: relative;
  top: -20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  font-size: 1.3rem;
  color: $gama;
  font-weight: 300;
  @media #{$mobile} {
    font-size: 1.2rem;
  }
}

blockquote {
  padding-left: 15px;
  border-left: 3px solid #eee;
}

hr {
  border: none;
  height: 2px;
  background: $black;
  width: 30%;
}

figure.highlight {
  width: 100%;
  margin: 0;
}

code,
tt {
  padding: 1px 0;
  font-family: $fontMonospace;
  font-size: 12px;
  line-height: 20px;
  background: #fff;
  border-radius: 2px;
  border-radius: 2px;
}

pre {
  box-sizing: border-box;
  margin: 0 0 1.75em;
  width: 100%;
  padding: 5px 10px;
  font-family: $fontMonospace;
  font-size: 1.2rem;
  line-height: 2rem;
  overflow: auto;
  background: #fff;
  border: 1px solid $epsilon;
  border-radius: 2px;
}

.wrapper-large,
.wrapper-normal {
  height: 100%;
  width: 96%;
  margin: 0 auto;
  @media #{$mobile} {
    width: 88%;
  }
  @media #{$tablet} {
    width: 88%;
  }
}

.wrapper-normal {
  max-width: 560px;
}

.wrapper-large {
  max-width: 810px;
}

.main{
  padding-top: 80px;
}

.spacing1{
  max-width: 1000px;
  margin: auto;

  @include medium{
    max-width: 744px;
  }

  @include small{
    max-width: 368px;
  }

  @include xsmall{
    max-width: 100%;
  }
}

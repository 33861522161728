.active{
  border-bottom: 2px;
  border-bottom-color: $delta;
  border-style: none none solid none;
  border-spacing: 10px;
}

.project-nav{
  position: fixed;
  text-align: center;
  transition: all 0.4s ease-in-out;
  padding-top: 0;
  width: 100%;
  max-width: 1000px;
  margin: auto;
  margin-top: -58px;
  background-color: white;
  z-index: 99;
  padding: 16px 0;

  .item{
    width:100px;
  }

}

.project-nav-small{
  position: fixed;
  text-align: right;
  transition: all 0.4s ease-in-out;
  width: 100%;
  max-width: 1000px;
  margin: auto;
  margin-top: -88px;
  background-color: white;
  z-index: 99;
  padding: 8px 0;

  .item{
    width:80px;
    //padding:0px 15px;
    padding-right: 15px;
    font-size: 14px;
  }

}

.project-header-section{
  margin-top: 40px;
  max-width: 1000px;
  margin: 40px auto;

  @include largeS{
    max-width: 744px;
  }

  @include mediumS{
    max-width: 368px;
  }
  @include small{
    max-width: calc(100vw-16px);
  }
}
.hero-img{
  height: 600px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 40px;

  @include mediumS{
    height: 300px;
  }
}

.project-content{
  text-align: left;

  h2,p,ul,h3,a,h4{
    max-width: 700px;
    margin: 32px auto;


  }
  h1,h2{
    text-align: center;
  }

  p,ul,li{
    text-align: left;
    color: $black;
    font-weight: 400;
  }

  iframe{
    border: none;
  }

  @include mediumS{
    max-width: 368px;
    margin: auto;
  }
  @include smallS{
    max-width: calc(100vw - 16px);
  }
}
.project-img{
  width: 1200px;
  display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.project-img-small{
    width: 500px;
}


.section-block{
  max-width: 700px;
  margin: auto;
  border: 2px solid $yellow;
  border-radius: 4px;
  padding: 16px 16px 16px 16px;
  box-shadow: $dp1 $yellow;

}

.persona-section{
  max-width: 700px;
  margin: auto;
  display: flex;
  justify-content: space-between;

  @include mediumS{
    flex-wrap: wrap;
    flex-direction: column;

  }
  //max-width: 100%;
  //flex-wrap: wrap;
  //margin: auto;
  ul{
    text-align: left;
    padding-left: 16px;
  }
  .persona-card{
    min-width: 280px;
    width: 290px;
    min-height: 300px;
    text-align: center;
    padding: 24px;
    border-radius: 4px;
    box-shadow: $dp1 $grey;
    img{width:100px;margin: auto;}

    @include mediumS{
      margin: 24px auto;
    }
    @include xsmall{
      min-width: 240px;
      width: 80%;
    }



    .card-header{
      display: flex;
      align-items: center;
      .header-text{
        text-align: left;
      }
      .header-img{
        padding-right: 24px;
      }
    }
  }
}

.project-image{
  max-width: 800px;
  margin:auto;

  .slide-dots {
    bottom: -16px;
    width: 100%;
    margin: 0;
    list-style: none;
    text-align: center;
    max-width: 800px;
    margin-bottom: 24px;
    position: relative;
}
}

.split-section{
  display: flex;
  justify-content: flex-start;
  max-width: 800px;
  margin: 40px auto;

  @include mediumS{
    flex-wrap: wrap;
    flex-direction: column;
  }

  .split-left{
    .foguang-photo{
      width: 400px;
    }
  }
  .split-right{
    padding: 0 16px;
    .first-par{
      margin: 0;
    }
  }
}

.brio-fin-video{
  max-width: 800px;
  margin: auto;
}

.section-title{
  margin-top: 64px !important;
  margin-bottom: 16px !important;
}

.first-section-par{
  margin-top: 16px !important;
}
ul{
  margin: 0 auto !important;
}

.list-title{
  margin-bottom: 8px !important;
}

.project-section{
  margin: 88px auto;
}

li{
    margin: 8px 0;
}

.numbered-list{
  list-style: decimal;
}

.gallery{
  display: flex;
  flex-wrap: nowrap;
  max-width: 1000px;
  margin: auto;

  .item{
    min-width: 300px;
    width: 330px;
  }

  .large-item{
    min-width: 300px;
    width:500px;
  }

  video{
    width: 100%;
  }
}
//------------ brio section --------------
.brio-animation{
  @include largeS{
    width: 100%;
  }
  @include xsmall{
    width: 100%;
  }
}

.brio-sm-proof{
  width: 100%;
  height: 280px;
  margin: auto;
  background-size: contain;
  background-repeat: no-repeat;

  @include mediumS{
    height: 120px;
  }

  //--------- project Nav ----------------------------------------------

}

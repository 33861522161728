.project-cards{
  display: flex;
  flex-wrap: wrap;
  margin: 80px auto;
  justify-content: space-between;
  h1,h2,h3,p{
    text-align: center;
  }

  @include largeS{
    justify-content: space-around;
  }
}

.project-card{
  width: 492px;
  height: 287px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 8px 0;


}

.title-bkg{
  padding: 24px;
  padding-bottom: 0;
  height: 263px;
  background-color: rgba(255,192,0,0.7);
  opacity: 0;
  -webkit-transition: opacity 0.5s ease-out;
  -moz-transition: opacity 0.5s ease-out;
  -o-transition: opacity 0.5s ease-out;
  transition: opacity 0.5s ease-out;

  &:hover{
    //background-color: rgba(255,192,0,0.7);
    opacity: 1;
  }
}

.brief{
  vertical-align: middle;
  margin: auto;
  margin-top: 60px;

  h3{
    margin: 16px 0;
  }
}

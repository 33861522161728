/*
 * general helpers
 */
.text-center {
  text-align: center;
}

.clearfix:after,
.clearfix:before {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}
/*
 * animations
 */
.animated {
  animation: fade-in-down 0.6s;
  animation-delay: 0.3s;
  animation-fill-mode: both;
}
@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

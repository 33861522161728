//max-width 1000px
$large-break-point: 1048px; // max-width 744px
$medium-break-point: 792px; //max-width 368px
$small-break-point: 400px; //100%

//screen sizes

//large size
@mixin large {
  @media (min-width: #{$large-break-point}) {
    @content;
  }
}

//medium size
@mixin medium {
  @media(min-width: #{$medium-break-point}) and (max-width: #{$large-break-point - 1px}) {
    @content;
  }
}

//small size
@mixin small {
  @media(min-width: #{$small-break-point}) and (max-width: #{$medium-break-point - 1px}) {
    @content;
  }
}

//xsmall size
@mixin xsmall {
  @media (max-width: #{$small-break-point - 1px}) {
    @content;
  }
}

//smaller than large sizes
@mixin largeS {
  @media (max-width: #{$large-break-point}) {
    @content;
  }
}

//smaller than medium size
@mixin mediumS {
  @media (max-width: #{$medium-break-point}) {
    @content;
  }
}

//smaller than small size
@mixin smallS {
  @media (max-width: #{$small-break-point}) {
    @content;
  }
}

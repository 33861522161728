$fontSans: "Helvetica Neue", Helvetica, Arial, sans-serif;
$fontMonospace: "Consolas", Liberation Mono, Menlo, Courier, monospace;

$alpha: #666;
$beta: #222;
$gama: #aaa;
$delta: #4b0082;
$epsilon: #ededed;
$black:#000000;
$yellow:rgb(255,192,0);
$grey: rgba(0,0,0,0.3);
$grey1: #f7f7f7;
$dp1: 0px 1px 10px -1px;

$mobile: "only screen and (max-width: 400px)";
$tablet: "only screen and (min-width: 400px) and (max-width: 1050px)";
$above: "only screen and (min-width: 780px)";
$below: "only screen and (max-width: 780px)";
